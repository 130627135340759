import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { mediaQuerys } from '../helpers/sizes';
import { ExternalLink } from './helper/Link';

const Wrapper = styled.div`
  position: absolute;
  bottom: 20px;
  z-index: 40;
  right: ${({ middle }) => (middle ? 'calc(50% + 20px)' : '20px')};

  @media ${mediaQuerys.mobile} {
    right: unset;
    width: 100%;
    left: 35px;
    bottom: 15px;
    ${({ hideOnMobile }) => (hideOnMobile ? `display: none;` : '')}
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  color: white;
  font-size: 12px;
`;

const Copyright = ({ author, href, revertImageBlock, isHero }) => {
  const { t } = useTranslation('copyright');
  return (
    <Wrapper hideOnMobile={!isHero} middle={revertImageBlock}>
      <Text>
        {t('photo')} <span style={{ fontWeight: 700 }}>{author}</span> {t('on')}{' '}
        <ExternalLink href={href}>
          <u>Unsplash</u>
        </ExternalLink>
      </Text>
    </Wrapper>
  );
};

export default Copyright;

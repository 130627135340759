import { lazy } from 'react';
import homePageLogo from './assets/magnet-me-LDcC7aCWVlo-unsplash_1600x1000.jpg';
import homePageLogo2 from './assets/slider/unterhaose.jpg';
import methodLogo from './assets/headway-5QgIuuBxKwM-unsplash-1600x1000.jpg';
import serviceLogo from './assets/jaime-lopes-0RDBOAdnbWM-unsplash_1600x1000.jpg';
import referencesLogo from './assets/johan-godinez-dDYRYivNzbI-unsplash-1600x1000.jpg';
import teamLogo from './assets/annie-spratt-wgivdx9dBdQ-unsplash-1600x1000.jpg';
import contactLogo from './assets//slider/kontakt-timpact.jpg';
import legalText from './Views/TextPages/legalNotice.js';
import privacyText from './Views/TextPages/privacyStatement.js';

export const defaultRoute = '/';

const routes = {
  '/': {
    view: lazy(() => import('./Views/Homepage')),
    exact: true,
    props: {
      logos: [homePageLogo, homePageLogo2],
      copyrightKey: ['magnet', false],
      showNew: [false, true],
      showCta: true,
      textBoxY: [210, 210],
      textBoxX: [940, 170],
      transKey: ['homepage', 'homepage2'],
      transparent: false,
    },
  },
  '/method': {
    view: lazy(() => import('./Views/Method')),
    exact: true,
    props: {
      logo: methodLogo,
      copyrightKey: 'headway',
      transKey: 'method',
      textBoxX: 860,
      transparent: false,
    },
  },
  '/services': {
    view: lazy(() => import('./Views/Services')),
    exact: true,
    props: {
      logo: serviceLogo,
      copyrightKey: 'jaimeLopes',
      transKey: 'services',
      textBoxX: 280,
      transparent: false,
    },
  },
  '/references': {
    view: lazy(() => import('./Views/References')),
    exact: true,
    props: {
      logo: referencesLogo,
      transKey: 'references',
      copyrightKey: 'johanGodinez',
      transparent: false,
    },
  },
  '/team': {
    view: lazy(() => import('./Views/Team')),
    exact: true,
    props: {
      logo: teamLogo,
      transKey: 'team',
      textBoxX: 330,
      transparent: false,
      copyrightKey: 'annieSpratt',
    },
  },
  '/contact': {
    view: lazy(() => import('./Views/Contact')),
    exact: true,
    props: {
      logo: contactLogo,
      transKey: 'contact',
      smallHero: true,
      transparent: false,
    },
  },
  '/legal-notice': {
    view: lazy(() => import('./Views/TextPage')),
    exact: true,
    props: {
      smallHero: true,
      legalText: legalText.haftung,
      text: legalText.legal,
      logo: contactLogo,
      transparent: false,
      headlineKey: 'legalHeadline',
    },
  },
  '/privacy-policy': {
    view: lazy(() => import('./Views/TextPage')),
    exact: true,
    props: {
      smallHero: true,
      text: privacyText,
      logo: contactLogo,
      transparent: false,
      headlineKey: 'dataHeadline',
    },
  },
};

export const navigation = [
  {
    textId: 'method',
    route: '/method',
  },
  {
    textId: 'services',
    route: '/services',
  },
  {
    textId: 'references',
    route: '/references',
  },
  {
    textId: 'team',
    route: '/team',
  },
  {
    textId: 'contact',
    route: '/contact',
  },
];

export default routes;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { blue, grey, red } from '../helpers/colors';
import Link, { ExternalLink } from './helper/Link';
import { socials } from '../helpers/logos';
import Maps from './Maps';
import { mediaQuerys } from '../helpers/sizes';

const Container = styled.div`
  height: auto;
  padding: ${({ subHeader }) =>
    subHeader ? '50px 250px 100px' : '90px 250px 100px'};
  ${({ small }) => {
    let set = '';
    if (small) {
      set += `
      height: auto;
      padding: 100px 250px;
    `;
    }
    return set;
  }};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background: ${({ blueBg, greyBg }) => {
    if (blueBg) {
      return blue;
    } else if (greyBg) {
      return grey;
    }
    return 'white';
  }};
  text-align: center;
  @media ${mediaQuerys.mobile} {
    padding: 55px 35px;
  }
`;

const SubHeader = styled.h5`
  font-size: 22px;
  color: ${({ white }) => (white ? 'white' : red)};
  font-weight: 600;
  margin: 0;
  margin-bottom: 70px;
  text-transform: uppercase;
`;
const Header = styled.h3`
  font-size: ${({ isBlueBg, isWhiteBG }) =>
    !isBlueBg && !isWhiteBG ? '40px' : '60px'};
  color: ${({ isBlueBg }) => (isBlueBg ? 'white' : blue)};
  font-weight: 900;
  margin: 0;
  margin-bottom: ${({ isMap }) => (isMap ? '10px' : `60x`)};
  @media ${mediaQuerys.mobile} {
    font-size: 30px;
    margin-bottom: ${({ isMap }) => (isMap ? '10px' : `40px`)};
    line-height: 36px;
  }
  padding: 0;
  ${({ small }) => {
    let set = '';
    if (small) {
      set += `
      margin-bottom: 30px;
    `;
    }
    return set;
  }};
`;
const Text = styled.p`
  font-size: 18px;
  line-height: 36px;
  color: ${({ white }) => (white ? 'white' : blue)};
  margin: 0;
  margin-bottom: 50px;
  font-weight: 200;
  @media ${mediaQuerys.mobile} {
    font-size: 14px;
    margin-bottom: 50px;
    line-height: 25px;
  }
`;
const Cta = styled(Link)`
  border: 4px solid ${red};
  color: ${red};
  width: 290px;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  height: 65px;
  &:hover {
    background: ${red};
    color: white;
  }
  @media ${mediaQuerys.mobile} {
    width: 100%;
    margin: 0 60px 14px;
  }
`;
const CtaExternal = styled(ExternalLink)`
  border: 4px solid ${red};
  color: ${red};
  width: 290px;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  height: 65px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${red};
    color: white;
  }
`;
const socialIconSize = 52;

const SocialsWrapper = styled.div`
  height: ${socialIconSize}px;
  display: flex;
  justify-content: center;
  color: blue;
  fill: red;
  margin-top: 50px;
  @media ${mediaQuerys.mobile} {
    svg {
      margin: 0 !important;
    }
    margin-top: 0;
    width: 100%;
    justify-content: space-between;
  }
`;

const TextBlock = ({
  langGroup,
  langKey,
  linkTo,
  blueBg = false,
  greyBg = false,
  smallHeight,
  isSocials = false,
  isMap = false,
  isExternal = false,
  subHeader = false,
}) => {
  const { t } = useTranslation(langGroup);
  const [hover, setHover] = useState(
    socials.reduce((a, e) => {
      a[e.id] = false;
      return a;
    }, {}),
  );

  const onHover = id => {
    const newState = { ...hover };
    Object.keys(newState).map(e => (newState[e] = false));
    setHover({ ...newState, [id]: true });
  };

  const onHoverLeave = async id => {
    setHover({ ...hover, [id]: false });
  };

  return (
    <Container
      blueBg={blueBg}
      greyBg={greyBg}
      small={smallHeight}
      map={isMap}
      subHeader={subHeader}
    >
      {subHeader && (
        <SubHeader
          style={{ marginBottom: isMap ? '50px' : '70px' }}
          white={isMap}
        >
          {t(`${langKey}SubHeader`)}
        </SubHeader>
      )}
      <Header
        small={smallHeight}
        isMap={isMap}
        isBlueBg={blueBg}
        isWhiteBG={!greyBg && !blueBg}
        dangerouslySetInnerHTML={{ __html: t(`${langKey}Header`) }}
      />
      {!!t(`${langKey}Text`).length && (
        <Text
          white={blueBg}
          dangerouslySetInnerHTML={{ __html: t(`${langKey}Text`) }}
        />
      )}
      {!isSocials && !isMap && (
        <>
          {linkTo && !isExternal && <Cta to={linkTo}>{t(`${langKey}Cta`)}</Cta>}
          {linkTo && isExternal && (
            <CtaExternal href={linkTo}>{t(`${langKey}Cta`)}</CtaExternal>
          )}
        </>
      )}
      {isSocials && !isMap && (
        <SocialsWrapper>
          {socials.map(({ icon: Icon, href, id }) => (
            <ExternalLink key={id} href={href}>
              <Icon
                onMouseEnter={() => onHover(id)}
                onMouseLeave={() => onHoverLeave(id)}
                style={{
                  width: socialIconSize,
                  height: socialIconSize,
                  margin: '0 25px',
                }}
                fill={hover[id] ? red : blue}
              />
            </ExternalLink>
          ))}
        </SocialsWrapper>
      )}
      {isMap && <Maps />}
    </Container>
  );
};

export default TextBlock;

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { headerHeight, mediaQuerys } from '../helpers/sizes';
import Link from './helper/Link';
import { blue, red } from '../helpers/colors';
import Copyright from './Copyright';
import 'react-awesome-slider/dist/styles.css';

const Slider = withAutoplay(styled(AwesomeSlider)`
  height: 100%;

  .awssld__bullets {
    bottom: 25px;
    z-index: 120;
    width: unset;
    margin-left: 50%;
    transform: translateX(-50%);
    @media ${mediaQuerys.mobile} {
      bottom: 50px;
    }
  }

  .awssld__bullets button {
    background: white !important;
  }

  .awssld__bullets button.awssld__bullets--active {
    background: rgba(229, 52, 18, 1) !important;
    transform: scale(1);
  }

  .awssld__controls__arrow-left:before,
  .awssld__controls__arrow-left:after,
  .awssld__controls__arrow-right:before,
  .awssld__controls__arrow-right:after {
    background-color: white;
  }
`);

const Container = styled.div`
  //height: calc(100vh - ${headerHeight}px); // change to this for removed fading header
  //margin-top: ${headerHeight}px;
  ${({ small }) =>
    small ? `height: calc(600px - ${headerHeight}px);` : `height: 1000px;`}
  width: 100%;
  position: relative;
  @media ${mediaQuerys.mobile} {
    height: 100vh;
  }
`;

const Bg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const TextBox = styled.div`
  position: absolute;
  z-index: 10;
  width: 560px;
  background: rgba(0, 0, 0, 0.15);
  padding: 15px;
  ${({ y, x, innerWidth }) => {
    return `
      @media ${mediaQuerys.mobile} {
        width: 100vw;
        background: transparent linear-gradient(180deg, #26338C00 0%, #26338C66 100%) 0% 0% no-repeat padding-box;
        bottom: 0;
        padding: 0 35px;
        padding-bottom: 90px;
      }
      @media ${mediaQuerys.desktop} {
        margin-top: ${y}px;
        margin-left: calc(${x}px - ${
      innerWidth < 1600 && innerWidth > 1480 && x > 500
        ? '(1600px - 100vw)'
        : '0px'
    });
      }
    `;
  }}
`;

const Cta = styled.div`
  height: 65px;
  padding: 14px 36px;
  border: 4px solid white;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  margin: 20px 0;
  cursor: pointer;
  width: fit-content;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${red};
  }
  @media ${mediaQuerys.mobile} {
    display: none;
  }
`;

const Heading = styled.h1`
  color: white;
  font-size: 70px;
  font-weight: 900;
  line-height: 79px;
  margin: 0;
  padding: 0;
  @media ${mediaQuerys.mobile} {
    font-size: 40px;
    line-height: 48px;
  }
`;
const Subline = styled.p`
  color: white;
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 40px;
  @media ${mediaQuerys.mobile} {
    font-size: 18px;
    line-height: 21px;
  }
`;

const Breaker = styled.div`
  height: 20px;
  background-color: ${blue};
  width: 100%;
  margin: 30px 0;
  @media ${mediaQuerys.mobile} {
    margin: 15px 0;
  }
`;

export const Sticker = styled.div`
  height: 130px;
  background-color: ${red};
  width: 130px;
  border-radius: 156px;
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  transform: rotate(-20deg);
  top: -80px;
  box-shadow: 0px 10px 10px #00000029;
  left: -102px;

  @media ${mediaQuerys.mobile} {
    height: auto;
    width: auto;
    border-radius: 0;
    transform: none;
    font-size: 16px;
    top: -20px;
    left: 35px;
    box-shadow: none;
    background-color: transparent;
    color: ${red};
  }
`;

const Hero = ({
  logo,
  logos,
  transKey,
  smallHero = false,
  showNew = false,
  showCta = false,
  textBoxY = 210,
  textBoxX = 200,
  copyrightKey,
  copyrightHref = 'https://unsplash.com/',
}) => {
  const { t } = useTranslation('heroHeader');
  const { t: s } = useTranslation('heroSubline');
  const { t: c } = useTranslation('heroCta');
  const { t: copy } = useTranslation('copyright');
  return (
    <>
      <Container small={smallHero}>
        {logo && !logos && (
          <>
            {copyrightKey && (
              <Copyright
                isHero
                author={copy(copyrightKey)}
                href={copyrightHref}
              />
            )}
            <Bg src={logo} alt="Background" />
            {!smallHero && (
              <TextBox y={textBoxY} x={textBoxX} innerWidth={window.innerWidth}>
                {showNew && <Sticker>{t('new')}</Sticker>}
                <Heading dangerouslySetInnerHTML={{ __html: t(transKey) }} />
                <Breaker />
                <Subline dangerouslySetInnerHTML={{ __html: s(transKey) }} />
                {showCta && (
                  <Cta>
                    <Link to="/contact">{c(transKey)}</Link>
                  </Cta>
                )}
              </TextBox>
            )}
          </>
        )}
        {logos && (
          <>
            <Slider
              className="hero"
              play={true}
              cancelOnInteraction={false}
              interval={6000}
            >
              {logos.map((e, i) => (
                <div
                  key={i + 'as'}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    display: 'block',
                  }}
                >
                  {copyrightKey[i] && (
                    <Copyright
                      author={copy(copyrightKey[i])}
                      href={copyrightHref}
                    />
                  )}
                  <Bg src={e} alt="Background" />
                  <TextBox
                    y={textBoxY[i]}
                    x={textBoxX[i]}
                    innerWidth={window.innerWidth}
                  >
                    {showNew[i] && <Sticker>{t('new')}</Sticker>}
                    <Heading
                      dangerouslySetInnerHTML={{ __html: t(transKey[i]) }}
                    />
                    <Breaker />
                    <Subline
                      dangerouslySetInnerHTML={{ __html: s(transKey[i]) }}
                    />
                    {showCta && (
                      <Cta>
                        <Link to="/contact">{c(transKey[i])}</Link>
                      </Cta>
                    )}
                  </TextBox>
                </div>
              ))}
            </Slider>
          </>
        )}
      </Container>
    </>
  );
};

export default Hero;

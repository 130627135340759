import { ReactComponent as Instagram } from '../assets/social/timpact_social1_instagram.svg';
import { ReactComponent as Linkedin } from '../assets/social/timpact_social1_linkedin.svg';
import { ReactComponent as Viemo } from '../assets/social/timpact_social1_vimeo.svg';
import { ReactComponent as Youtube } from '../assets/social/timpact_social1_youtube.svg';

import { ReactComponent as alpinConvention } from '../assets/referenzen/alpin-convention.svg';
import { ReactComponent as amplifon } from '../assets/referenzen/amplifon.svg';
import { ReactComponent as bayer } from '../assets/referenzen/bayer.svg';
import { ReactComponent as braincheck } from '../assets/referenzen/braincheck.svg';
import { ReactComponent as carglass } from '../assets/referenzen/carglass.svg';
import { ReactComponent as commerzbank } from '../assets/referenzen/commerzbank.svg';
import { ReactComponent as db } from '../assets/referenzen/db.svg';
import { ReactComponent as esg } from '../assets/referenzen/esg.svg';
import { ReactComponent as expomobilia } from '../assets/referenzen/expomobilia.svg';
import { ReactComponent as famab } from '../assets/referenzen/famab.svg';
import { ReactComponent as gilead } from '../assets/referenzen/gilead.svg';
import { ReactComponent as hanschur } from '../assets/referenzen/hanschur.svg';
import { ReactComponent as healthcareConvention } from '../assets/referenzen/healthcare-convention.svg';
import { ReactComponent as insglueck } from '../assets/referenzen/insglueck.svg';
import { ReactComponent as jaegerHaeckerhase } from '../assets/referenzen/jaeger-haeckerhase.svg';
import { ReactComponent as kfw } from '../assets/referenzen/kfw.svg';
import { ReactComponent as lcc } from '../assets/referenzen/lcc.svg';
import { ReactComponent as man } from '../assets/referenzen/man.svg';
import { ReactComponent as porsche } from '../assets/referenzen/porsche.svg';
import { ReactComponent as roto } from '../assets/referenzen/roto.svg';
import { ReactComponent as sanofi } from '../assets/referenzen/sanofi.svg';
import { ReactComponent as sbb } from '../assets/referenzen/sbb.svg';
import { ReactComponent as tallyStream } from '../assets/referenzen/tally-stream.svg';
import { ReactComponent as toyota } from '../assets/referenzen/toyota.svg';
import { ReactComponent as vbi } from '../assets/referenzen/vbi.svg';

export { default as sec1Img } from '../assets/switch/chemie.jpg';
export { default as sec2Img } from '../assets/switch/Idee.jpg';
export { default as sec3Img } from '../assets/switch/geschenk.jpg';
export { default as sec4Img } from '../assets/switch/fit auftritt.jpg';
export { default as sec5Img } from '../assets/switch/authentizizaet.jpg';
export { default as sec6Img } from '../assets/thisisengineering-raeng-DbLlKd8u2Rw-unsplash.jpg';
export { default as sec1Logo } from '../assets/methode/Timpact_Methode04_RGB_1.svg';
export { default as sec2Logo } from '../assets/methode/Timpact_Methode04_RGB_2.svg';
export { default as sec3Logo } from '../assets/methode/Timpact_Methode04_RGB_3.svg';
export { default as sec4Logo } from '../assets/methode/Timpact_Methode04_RGB_4.svg';
export { default as sec5Logo } from '../assets/methode/Timpact_Methode04_RGB_5.svg';
export { default as sec6Logo } from '../assets/methode/Timpact_Methode04_RGB_6.svg';

export const sec1Author = 'you-x-ventures';
export const sec2Author = 'johnsonWang';
export const sec3Author = 'priscillaDuPreez';
export const sec4Author = 'krakenimages';
export const sec5Author = 'productSchoolDL';
export const sec6Author = 'thisisengineeringRaeng';

export { default as Timp_Themen01_RGB_1a } from '../assets/leistungen/Timp_Themen01_RGB_1a.svg';
export { default as Timp_Themen01_RGB_1b } from '../assets/leistungen/Timp_Themen01_RGB_1b.svg';
export { default as Timp_Themen01_RGB_2 } from '../assets/leistungen/Timp_Themen01_RGB_2.svg';
export { default as Timp_Themen01_RGB_3 } from '../assets/leistungen/Timp_Themen01_RGB_3.svg';
export { default as Timp_Themen01_RGB_4a } from '../assets/leistungen/Timp_Themen01_RGB_4a.svg';
export { default as Timp_Themen01_RGB_4b } from '../assets/leistungen/Timp_Themen01_RGB_4b.svg';
export { default as Timp_Themen01_RGB_5 } from '../assets/leistungen/Timp_Themen01_RGB_5.svg';
export { default as Timp_Themen01_RGB_6 } from '../assets/leistungen/Timp_Themen01_RGB_6.svg';
export { default as Timp_Themen01_RGB_7a } from '../assets/leistungen/Timp_Themen01_RGB_7a.svg';
export { default as Timp_Themen01_RGB_7b } from '../assets/leistungen/Timp_Themen01_RGB_7b.svg';
export { default as Timp_Themen01_RGB_7c } from '../assets/leistungen/Timp_Themen01_RGB_7c.svg';

export const socials = [
  {
    icon: Instagram,
    href: 'https://www.instagram.com/timpact/?igshid=1jj2cxnwge3wm',
    id: 'insta',
  },
  {
    icon: Youtube,
    href: 'https://www.youtube.com/channel/UC3RGMaIiJdlSZ_G6AlyNV0Q',
    id: 'youtube',
  },
  {
    icon: Viemo,
    href: 'https://vimeo.com/user112972130',
    id: 'viemo',
  },
  {
    icon: Linkedin,
    href: 'https://www.linkedin.com/company/timpact-gmbh/',
    id: 'linked',
  },
];

export const refs = [
  alpinConvention,
  amplifon,
  bayer,
  braincheck,
  carglass,
  commerzbank,
  db,
  esg,
  expomobilia,
  famab,
  gilead,
  hanschur,
  healthcareConvention,
  insglueck,
  jaegerHaeckerhase,
  kfw,
  lcc,
  man,
  porsche,
  roto,
  sanofi,
  sbb,
  tallyStream,
  toyota,
  vbi,
];

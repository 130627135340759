import { useEffect } from 'react';
import routes from '../routes';

const RedirectToHome = () => {
  useEffect(() => {
    if (Object.keys(routes).indexOf(window.location.pathname) === -1) {
      window.location.href = '/';
    }
  }, []);
  return <div></div>;
};

export default RedirectToHome;

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Footer from './components/Footer';
import './App.css';
import HeaderBar from './components/HeaderBar';
import routes, { defaultRoute } from './routes';
import Hero from './components/Hero';
import Loading from './Views/Loading/Loading';
import Homepage from './Views/Homepage';
import RedirectToHome from './Views/RedirectToHome';

const { props: defaultRouteProps } = routes[defaultRoute];

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Route
          path="/"
          exact
          render={() => (
            <>
              <HeaderBar {...defaultRouteProps} />
              {!defaultRouteProps.noHero && (
                <Hero {...defaultRouteProps}></Hero>
              )}
              <Homepage {...defaultRouteProps} />
              <Footer {...defaultRouteProps} />
            </>
          )}
        />
        {Object.entries(routes)
          .filter(([key]) => key !== defaultRoute)
          .map(
            ([
              path,
              { view: View, exact = false, props = { noHero: false } },
            ]) => (
              <Route
                key={path}
                path={path}
                exact={exact}
                render={() => (
                  <>
                    <HeaderBar {...props} />
                    {!props.noHero && <Hero {...props}></Hero>}
                    <View {...props} />
                    <Footer {...props} />
                  </>
                )}
              />
            ),
          )}
        <RedirectToHome></RedirectToHome>
      </Suspense>
    </Router>
  );
}

export default App;

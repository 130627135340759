import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`;

export const LinkExt = styled.a`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:active {
    color: inherit;
  }
`;

export const ExternalLink = (props) => {
  return <LinkExt target={props.openSelf ? '_self' : '_blank'} {...props} />;
};

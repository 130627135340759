import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import propTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import Link from './helper/Link';
import { headerHeight, mediaQuerys } from '../helpers/sizes';
import { blue } from '../helpers/colors';
import logo from '../assets/Timpact_Logo2018-RGB-noclaim.svg';
import { CenterVertical, Logo } from './helper/GeneralHelpers';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import { navigation } from '../routes';
import i18N from '../i18n';

const HeaderWrapper = styled.header`
  height: ${headerHeight}px;
  z-index: 10;

  @media ${mediaQuerys.mobile} {
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  background: transparent;
  transition: all 0.4s ease-in-out;
  z-index: 30;
  font-weight: 600;
  color: ${blue};
  background-color: white;

  @media ${mediaQuerys.mobile} {
    background-color: transparent;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  height: ${headerHeight}px;
  display: flex;
  justify-content: space-between;
  padding: 0 150px 0 50px;
  position: relative;

  @media (max-width: 880px) {
    padding: 0 150px 0 10px;
  }
  @media ${mediaQuerys.mobile} {
    padding: 0 0 0 50px;
  }
`;
const Menu = styled.div`
  height: 100%;
  color: inherit;
  display: none;
  ${({ mobile, notMobile }) => {
    if (mobile) {
      return `
      @media ${mediaQuerys.mobile} {
        display: block;
        position: absolute;
        right: 0;
        width: ${headerHeight}px;
        height: 100%;
      }
    `;
    } else if (notMobile) {
      return `
      @media ${mediaQuerys.desktop} {
        display: flex;
      }
    `;
    } else {
      return `display: flex;`;
    }
  }}
`;
const MenuItem = styled.div`
  height: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 15px;
  text-transform: uppercase;
  color: inherit;
  transition: transform 0.2s ease-in-out;
  ${({ noHover, noPadding }) => {
    let collect = '';
    if (!noHover) {
      collect += `
      &:hover {
        transform: scale(1.06);
      }  
    `;
    }
    if (noPadding) {
      collect += `
      padding: 0 0 0 20px;
    `;
    }
    return collect;
  }}
`;

const LangWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 40px;
  color: ${blue};
  transition: all 0.4s ease-in-out;
  font-weight: 200;
  @media (max-width: 880px) {
    right: 20px;
  }
  @media ${mediaQuerys.mobile} {
    display: ${({ mobile }) => (mobile ? `flex` : `none`)};
  }

  ${({ mobile }) =>
    mobile
      ? `
    bottom:40px;
    left: 0;
    width: 100%;
    right: unset;
    height: unset;
  `
      : ''}
`;
const LangItem = styled.div`
  text-transform: uppercase;
  border-right: 1px solid black;
  padding: 0 10px;
  cursor: pointer;
  ${({ active }) => {
    let set = '';
    if (active) {
      set += `font-weight: 600;`;
    }
    return set;
  }}
  &:last-child {
    border: none;
  }
`;

const HeaderBar = ({ transparent = true }) => {
  const { t } = useTranslation('header');
  const [scrolled, setScrolled] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [lang, setLang] = useState(i18N.language);

  const scrollHandler = () => {
    if (window.pageYOffset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const onLanguageChange = type => () => {
    i18N.changeLanguage(type).then(() => {
      setLang(type);
    });
  };

  useEffect(() => {
    if (transparent) {
      scrollHandler();
      window.addEventListener('scroll', scrollHandler);
      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
    return false;
  }, [transparent]);

  return (
    <HeaderWrapper>
      <Container scrolled={scrolled} transparent={transparent}>
        <Wrapper>
          <Menu>
            <CenterVertical>
              <Link to="/">
                <Logo src={logo} />
              </Link>
            </CenterVertical>
          </Menu>
          <Menu notMobile>
            {navigation.map((e, i, arr) => (
              <Link key={e.route} to={e.route}>
                <MenuItem noPadding={i === arr.length - 1}>
                  {t(e.textId)}
                </MenuItem>
              </Link>
            ))}
          </Menu>
          <Menu mobile>
            <MenuItem
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenMenu(true)}
            >
              <MenuIcon></MenuIcon>
            </MenuItem>
            <Drawer
              anchor="right"
              open={openMenu}
              onClose={() => setOpenMenu(false)}
            >
              <List style={{ position: 'relative', minHeight: '100%' }}>
                {navigation.map(e => (
                  <Link key={e.route} to={e.route}>
                    <ListItem>
                      <ListItemText
                        disableTypography
                        primary={
                          <MenuItem style={{ color: blue, fontWeight: 600 }}>
                            {t(e.textId)}
                          </MenuItem>
                        }
                      />
                    </ListItem>
                  </Link>
                ))}
                <LangWrapper mobile>
                  <LangItem
                    active={lang.toLowerCase() === 'de'}
                    onClick={onLanguageChange('de')}
                  >
                    DE
                  </LangItem>
                  <LangItem
                    active={lang.toLowerCase() === 'en'}
                    onClick={onLanguageChange('en')}
                  >
                    EN
                  </LangItem>
                </LangWrapper>
              </List>
            </Drawer>
          </Menu>
          <LangWrapper scrolled={scrolled} transparent={transparent}>
            <LangItem
              active={lang.toLowerCase() === 'de'}
              onClick={onLanguageChange('de')}
            >
              DE
            </LangItem>
            <LangItem
              active={lang.toLowerCase() === 'en'}
              onClick={onLanguageChange('en')}
            >
              EN
            </LangItem>
          </LangWrapper>
        </Wrapper>
      </Container>
    </HeaderWrapper>
  );
};
HeaderBar.propTypes = {
  hideLoginBtn: propTypes.bool,
  transparent: propTypes.bool,
};

export default HeaderBar;

import React from 'react';
import { HomepageLayout } from '../components/helper/Layouts';
import ImageBlock from '../components/ImageBlock';
import TextBlock from '../components/TextBlock';
import CircleBlock from '../components/CircleBlock';
import {
  sec1Img,
  sec1Logo,
  sec2Img,
  sec2Logo,
  sec3Img,
  sec3Logo,
} from '../helpers/logos';

const Homepage = () => {
  return (
    <>
      <TextBlock langGroup="homepage" langKey="block" linkTo="/contact" />
      <ImageBlock
        transGroup="method"
        logo={sec1Logo}
        img={sec1Img}
        transKey="sec1"
      />
      <ImageBlock
        transGroup="method"
        logo={sec2Logo}
        img={sec2Img}
        revert
        transKey="sec2"
      />
      <ImageBlock
        transGroup="method"
        logo={sec3Logo}
        img={sec3Img}
        transKey="sec3"
      />
      <CircleBlock
        langGroup="homepage"
        langKey="circleBlock"
        linkTo="/method"
      />
    </>
  );
};

export default HomepageLayout(Homepage);

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  //transform: scale(0.5);
`;
const Wrapper = styled.div`
  max-width: 1600px;
  width: 100vw;
  position: relative;
  box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
`;

const BodyWrapper = ({ children }) => {
  return (
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BodyWrapper>
      <App />
    </BodyWrapper>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const legalDe = `
  <p><strong>timpact. GmbH</strong></p>
  <p>Bülowstraße 66</p>
  <p>10783 Berlin</p><br/>
  <p>Tel.: +49 (0) 30 4000 68 642</p>
  <p>E-Mail: info@timpact.com</p>
  <p>//</p>
  <p><strong>Geschäftsführung</strong></p>
  <p>Dr. Gertrud Kemper</p>
  <p><strong>Handelsregistereintrag</strong></p>
  <p>Amtsgericht Berlin (Charlottenburg), HRB 179687</p>
  <p><strong>USt-IdNr.</strong></p>
  <p>DE 307850276</p>
  <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</strong></p>
  <p>Dr. Gertrud Kemper</p>
  <p>//</p>
  <p><strong>Layout & Grafiken</strong></p>
  <p>Simon Wagner / ↑ <a href="/">timpact.®</a></p>
  <p><strong>Web</strong></p>
  <p>Carlo Nölle / ↑ <a target="_blank" href="https://carlonoelle.de">Web</a></p>
  <p><strong>Logo</strong></p>
  <p>Tobias Hampel / ↑ <a target="_blank" href="#">Herr Hampel</a></p>
  <p><strong>Portraits</strong></p>
  <p>Stefan Stark / ↑ <a target="_blank" href="#">starkphotoproduction</a></p>
  <p><strong>Bildnachweis</strong></p>
  <p>↑ <a target="_blank" href="https://unsplash.com">unsplash.com</a></p>
  <p>//</p>
  <p>timpact.® ist als Wortmarke unter der Registernummer 302016219701 und dem Aktenzeichen 33020162197017 / 35 vom Deutschen Patent- und Markenamt geschützt.</p>
  <p>//</p>
  <p><strong>Information nach dem Verbraucherstreitbeilegungsgesetz (VSBG)</strong></p>
  <p>Die Europäische Kommission stellt unter ↑ <a target="_blank" href="https://ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</a> eine Plattform zur Online-Streitbeilegung bereit, die Verbraucher für die Beilegung einer Streitigkeit nutzen können und auf der weitere Informationen zum Thema Streitschlichtung zu finden sind.</p>
  <p>Wir sind weder verpflichtet noch dazu bereit, im Falle einer Streitigkeit mit einem Verbraucher an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
`;

export const legalEn = `
<p><strong>timpact. GmbH</strong></p>
<p>Bülowstraße 66</p>
<p>10783 Berlin</p><br/>
<p>Tel.: +49 (0) 30 4000 68 642</p>
<p>E-Mail: info@timpact.com</p>
<p>//</p>
<p><strong>Geschäftsführung</strong></p>
<p>Dr. Gertrud Kemper</p>
<p><strong>Handelsregistereintrag</strong></p>
<p>Amtsgericht Berlin (Charlottenburg), HRB 179687</p>
<p><strong>USt-IdNr.</strong></p>
<p>DE 307850276</p>
<p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</strong></p>
<p>Dr. Gertrud Kemper</p>
<p>//</p>
<p><strong>Layout & Grafiken</strong></p>
<p>Simon Wagner / ↑ <a href="/">timpact.®</a></p>
<p><strong>Web</strong></p>
<p>Carlo Nölle / ↑ <a target="_blank" href="https://carlonoelle.de">Web</a></p>
<p><strong>Logo</strong></p>
<p>Tobias Hampel / ↑ <a target="_blank" href="#">Herr Hampel</a></p>
<p><strong>Portraits</strong></p>
<p>Stefan Stark / ↑ <a target="_blank" href="#">starkphotoproduction</a></p>
<p><strong>Bildnachweis</strong></p>
<p>↑ <a target="_blank" href="https://unsplash.com">unsplash.com</a></p>
<p>//</p>
<p>timpact.® ist als Wortmarke unter der Registernummer 302016219701 und dem Aktenzeichen 33020162197017 / 35 vom Deutschen Patent- und Markenamt geschützt.</p>
<p>//</p>
<p><strong>Information nach dem Verbraucherstreitbeilegungsgesetz (VSBG)</strong></p>
<p>Die Europäische Kommission stellt unter ↑ <a target="_blank" href="https://ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</a> eine Plattform zur Online-Streitbeilegung bereit, die Verbraucher für die Beilegung einer Streitigkeit nutzen können und auf der weitere Informationen zum Thema Streitschlichtung zu finden sind.</p>
<p>Wir sind weder verpflichtet noch dazu bereit, im Falle einer Streitigkeit mit einem Verbraucher an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
`;

export const haftungDe = `
<p><strong>Haftung für Inhalte</strong></p>
<p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
<p>//</p>
<p><strong>Haftung für Links</strong></p>
<p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanenteinhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</a></p>
<p>//</p>
<p><strong>Urheberrecht</strong></p>
<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerdenvon Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</a></p>
<p>//</p>
<p><strong>Quellenangabe:</strong> ↑ <a target="_blank" href="https://www.e-recht24.de">eRecht24</a></p>
`;

export const haftungEn = `
<p><strong>Haftung für Inhalte</strong></p>
<p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
<p>//</p>
<p><strong>Haftung für Links</strong></p>
<p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanenteinhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</a></p>
<p>//</p>
<p><strong>Urheberrecht</strong></p>
<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerdenvon Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</a></p>
<p>//</p>
<p><strong>Quellenangabe:</strong> ↑ <a target="_blank" href="https://www.e-recht24.de">eRecht24</a></p>
`;

const combined = {
  legal: { en: legalEn, de: legalDe },
  haftung: { en: haftungEn, de: haftungDe },
};

export default combined;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { blue, red } from '../helpers/colors';
import {
  sec1Logo,
  sec2Logo,
  sec3Logo,
  sec4Logo,
  sec5Logo,
  sec6Logo,
} from '../helpers/logos';
import { mediaQuerys } from '../helpers/sizes';
import Link from './helper/Link';

const Container = styled.div`
  height: auto;
  width: 100%;
  padding: 90px 250px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background: white;
  text-align: center;
  background: ${blue};
`;

const Header = styled.h3`
  font-size: 60px;
  color: white;
  font-weight: 900;
  margin: 0;
  margin-bottom: 60px;
  padding: 0;
`;
const circleSize = `140px`;
const CirclesWrapper = styled.div`
  margin-bottom: 50px;
  height: ${circleSize};
  display: flex;
  justify-content: center;
  @media ${mediaQuerys.mobile} {
    display: none;
  }
`;
const Circle = styled.div`
  height: ${circleSize};
  width: ${circleSize};
  margin: 0 15px;
  border-radius: ${circleSize};
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;
const CircleImg = styled.img`
  width: 90%;
`;
const Cta = styled(Link)`
  border: 4px solid white;
  color: white;
  width: 290px;
  background: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
  height: 65px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${red};
    color: white;
  }
`;

const CircleBlock = ({ langGroup, langKey, linkTo }) => {
  const { t } = useTranslation(langGroup);
  return (
    <Container>
      <Header dangerouslySetInnerHTML={{ __html: t(`${langKey}Header`) }} />
      <CirclesWrapper>
        <Circle>
          <CircleImg src={sec1Logo} />
        </Circle>
        <Circle>
          <CircleImg src={sec2Logo} />
        </Circle>
        <Circle>
          <CircleImg src={sec3Logo} />
        </Circle>
        <Circle>
          <CircleImg src={sec4Logo} />
        </Circle>
        <Circle>
          <CircleImg src={sec5Logo} />
        </Circle>
        <Circle>
          <CircleImg src={sec6Logo} />
        </Circle>
      </CirclesWrapper>
      <Cta to={linkTo}>{t(`${langKey}Cta`)}</Cta>
    </Container>
  );
};

export default CircleBlock;

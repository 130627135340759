import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import i18n from '../i18n';

const Maps = () => {
  const { isLoaded, loadError } = useLoadScript({
    language: i18n.language,
    googleMapsApiKey: 'AIzaSyA3567WhruFKJTybnf_Ilo2lWb63OwEQVA',
  });
  const mapOptions = {
    zoom: 14,
    center: { lat: 52.49652, lng: 13.36705 },
    mapContainerStyle: {
      height: '400px',
      width: '100%',
    },
  };
  const markerOptions = {
    position: { lat: 52.49652, lng: 13.36705 },
  };

  const map = () => (
    <GoogleMap {...mapOptions}>
      <Marker {...markerOptions} />
    </GoogleMap>
  );

  if (loadError) {
    return <p>Da ist etwas schief gelaufen...</p>;
  }

  return isLoaded ? map() : 'Loading...';
};

export default Maps;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { blue, grey, red } from '../helpers/colors';
import { Center, CenterVertical, Logo } from './helper/GeneralHelpers';
import logo from '../assets/Timpact_Logo2018-RGB-noclaim.svg';
import Link, { ExternalLink } from './helper/Link';
import { socials } from '../helpers/logos';
import { mediaQuerys } from '../helpers/sizes';

export const footerHeight = 340;

const Container = styled.footer`
  width: 100%;
  height: ${footerHeight}px;
  background: ${grey};
  color: ${blue};
  padding: 0 90px;
  @media ${mediaQuerys.mobile} {
    height: auto;
    padding: 40px 35px 50px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  ${({ mobile }) => (mobile ? `display: none;` : '')}
  @media ${mediaQuerys.mobile} {
    ${({ mobile }) => (!mobile ? `display: none;` : 'display: block;')}
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;

  @media ${mediaQuerys.mobile} {
    ${({ notOnMobile }) => (notOnMobile ? `display: none` : '')}
  }
`;
const Column = styled.div`
  width: 300px;
  ${({ big, underline }) => {
    let collect = '';
    if (big) {
      collect += `flex-grow: 2;`;
    }
    if (underline) {
      collect += `
        height: 32px;
        border-bottom: 2px solid ${blue};
      `;
    }
    return collect;
  }}
  @media ${mediaQuerys.mobile} {
    width: auto;
  }
`;
const Item = styled.div`
  padding: 20px 0 0 25px;
  @media ${mediaQuerys.mobile} {
    padding: 20px 0 0 0;
    p,
    a {
      font-size: 15px;
    }
  }
`;

const socialIconSize = 26;

const SocialsWrapper = styled.div`
  height: ${socialIconSize}px;
  display: flex;
  justify-content: center;
  color: blue;
  fill: red;
`;

const Footer = () => {
  const { t } = useTranslation('footer');
  const hoverState = socials.reduce((a, e) => {
    a[e.id] = false;
    return a;
  }, {});
  const [hover, setHover] = useState(hoverState);

  const onHover = id => {
    const newState = { ...hover };
    Object.keys(newState).map(e => (newState[e] = false));
    setHover({ ...newState, [id]: true });
  };

  const onHoverLeave = async id => {
    setHover({ ...hover, [id]: false });
  };

  return (
    <Container>
      <Center>
        <Wrapper>
          <Row>
            <Column style={{ width: 138 }}>
              <Logo src={logo}></Logo>
            </Column>
            <Column big style={{ padding: '0 34px 0 16px' }}>
              <Column big underline style={{ width: '100%' }}></Column>
            </Column>
            <Column style={{ width: 156 }}>
              <CenterVertical
                style={{ color: red, fontWeight: 600, fontSize: 18 }}
              >
                {t('talks')}
              </CenterVertical>
            </Column>
          </Row>
          <Row>
            <Column style={{ width: 138 }} />
            <Column big style={{ padding: '0 20px' }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Column>
                  <Item>
                    <p style={{ fontWeight: 600, margin: 0 }}>{t('company')}</p>
                    <p style={{ margin: 0 }}>{t('street')}</p>
                  </Item>
                </Column>
                <Column>
                  <Item>
                    <Row style={{}}>
                      <p style={{ fontWeight: 600, margin: 0, width: 70 }}>
                        {t('phone')}
                      </p>
                      <ExternalLink href={`tel:${t('phoneNumber')}`}>
                        {t('phoneNumber')}
                      </ExternalLink>
                    </Row>
                    <Row>
                      <p style={{ fontWeight: 600, margin: 0, width: 70 }}>
                        {t('mail')}
                      </p>
                      <ExternalLink href={`mailto:${t('mailAddress')}`}>
                        {t('mailAddress')}
                      </ExternalLink>
                    </Row>
                  </Item>
                </Column>
                <Column>
                  <SocialsWrapper>
                    {socials.map(({ icon: Icon, href, id }) => (
                      <ExternalLink key={id} href={href}>
                        <Icon
                          onMouseEnter={() => onHover(id)}
                          onMouseLeave={() => onHoverLeave(id)}
                          style={{
                            width: socialIconSize,
                            height: socialIconSize,
                            margin: '0 13px',
                          }}
                          fill={hover[id] ? red : blue}
                        />
                      </ExternalLink>
                    ))}
                  </SocialsWrapper>
                </Column>
              </Row>
            </Column>
            <Column style={{ fontWeight: 600, fontSize: 15, width: 156 }}>
              <div style={{ marginTop: 19 }}>
                <div style={{ marginBottom: 10 }}>
                  <Link to="/legal-notice">{t('legal')}</Link>
                </div>
                <div>
                  <Link to="/privacy-policy">{t('privacy')}</Link>
                </div>
              </div>
            </Column>
          </Row>
        </Wrapper>
        <Wrapper mobile>
          <Row>
            <Column style={{ width: 138 }}>
              <Logo src={logo}></Logo>
            </Column>
            <Column big>
              <Column big underline></Column>
            </Column>
          </Row>
          <Column big>
            <Column>
              <Item>
                <p style={{ fontWeight: 600, margin: 0 }}>{t('company')}</p>
                <p style={{ margin: 0 }}>{t('street')}</p>
              </Item>
            </Column>
            <Row>
              <Column>
                <Item>
                  <Row>
                    <p style={{ fontWeight: 600, margin: 0, width: 70 }}>
                      {t('phone')}
                    </p>
                    <ExternalLink href={`tel:${t('phoneNumber')}`}>
                      {t('phoneNumber')}
                    </ExternalLink>
                  </Row>
                  <Row>
                    <p style={{ fontWeight: 600, margin: 0, width: 70 }}>
                      {t('mail')}
                    </p>
                    <ExternalLink href={`mailto:${t('mailAddress')}`}>
                      {t('mailAddress')}
                    </ExternalLink>
                  </Row>
                </Item>
              </Column>
            </Row>
            <Row style={{ marginTop: 50, marginBottom: 50 }}>
              <Column style={{ width: 156 }}>
                <Center style={{ color: red, fontWeight: 600, fontSize: 18 }}>
                  {t('talks')}
                </Center>
              </Column>
              <Column big style={{ paddingLeft: 16 }}>
                <Column
                  big
                  underline
                  style={{ width: '100%', height: 21 }}
                ></Column>
              </Column>
            </Row>
            <Row>
              <Column style={{ fontWeight: 600, fontSize: 15, width: 156 }}>
                <div>
                  <div style={{ marginBottom: 10 }}>
                    <Link to="/legal-notice">{t('legal')}</Link>
                  </div>
                  <div>
                    <Link to="/privacy-policy">{t('privacy')}</Link>
                  </div>
                </div>
              </Column>
            </Row>
          </Column>
        </Wrapper>
      </Center>
    </Container>
  );
};

export default Footer;

import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Center } from '../../components/helper/GeneralHelpers';

const Container = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
`;

const Loading = () => {
  return (
    <Container>
      <Center>
        <CircularProgress />
      </Center>
    </Container>
  );
};

export default Loading;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translations from './translations';
const defaultLang = 'de';
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: defaultLang,
    debug: false,
    resources: translations,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
if (window.location.origin.includes('timpact.com')) {
  i18n.changeLanguage('en');
} else {
  i18n.changeLanguage('de');
}
const currentLang = window.localStorage.getItem('currentLang');
if (currentLang) {
  i18n.changeLanguage(currentLang);
} else {
  window.localStorage.setItem('currentLang', defaultLang);
  i18n.changeLanguage(defaultLang);
}

i18n.on('languageChanged', (lang) =>
  window.localStorage.setItem('currentLang', lang),
);

export default i18n;

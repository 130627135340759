import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { blue, grey, red } from '../helpers/colors';
import { mediaQuerys } from '../helpers/sizes';
import Copyright from './Copyright';
import Link from './helper/Link';

const Container = styled.section`
  height: 520px;
  width: 100%;
  display: flex;
  position: relative;
  ${({ revert }) => {
    if (revert) {
      return `
      flex-flow: row-reverse;
    `;
    }
  }}
  @media ${mediaQuerys.mobile} {
    flex-flow: column-reverse;
    height: auto;
  }
`;

const TextBlock = styled.div`
  width: 50%;
  padding: ${({ revert }) => (revert ? '0 50px 0 200px' : '0 200px 0 50px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${grey};
  @media ${mediaQuerys.mobile} {
    width: 100%;
    padding: 128px 50px 98px;
  }
`;

const ImgBlock = styled.img`
  height: 100%;
  width: 50%;
  border: none;
  object-fit: cover;
  @media ${mediaQuerys.mobile} {
    width: 100%;
    height: 290px;
  }
`;

const LogoWrapper = styled.div`
  width: 290px;
  height: 290px;
  border-radius: 290px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${mediaQuerys.mobile} {
    width: 138px;
    height: 138px;
    top: 290px;
  }
`;
const LogoImg = styled.img`
  width: 80%;
`;

const Header = styled.h3`
  font-size: 30px;
  font-weight: 900;
  color: ${blue};
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
`;
const SubHeader = styled.h4`
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 200;
  color: ${blue};
  margin: 0;
  padding: 0;
`;
const Subline = styled.p`
  font-size: 18px;
  color: ${blue};
  margin: 0;
  padding: 0;
  font-weight: 100;
  margin-bottom: 30px;
`;
const Cta = styled(Link)`
  font-size: 15px;
  color: ${red};
  margin: 0;
  padding: 0;
  font-weight: 100;
`;

const Logo = ({ logo }) => {
  return (
    <LogoWrapper>
      <LogoImg alt="" src={logo} />
    </LogoWrapper>
  );
};

const ImageBlock = ({
  logo,
  transKey,
  transGroup,
  img,
  revert,
  hideCta = false,
  anchor = false,
  copyrightKey,
  copyrightHref = 'https://unsplash.com/',
}) => {
  const { t } = useTranslation(transGroup);
  const { t: copy } = useTranslation('copyright');

  return (
    <Container id={anchor ? anchor : null} revert={revert}>
      <TextBlock revert={revert}>
        <div>
          {anchor && (
            <SubHeader>
              {t(`pageNavi${anchor.charAt(0).toUpperCase() + anchor.slice(1)}`)}
            </SubHeader>
          )}
          <Header>{t(`${transKey}Header`)}</Header>
          {t(`${transKey}Subline`) && (
            <Subline>{t(`${transKey}Subline`)}</Subline>
          )}
          {!hideCta && <Cta to={'/method'}>{'> ' + t(`${transKey}Cta`)}</Cta>}
        </div>
      </TextBlock>
      <Logo logo={logo} />
      {copyrightKey && (
        <Copyright
          revertImageBlock={revert}
          author={copy(copyrightKey)}
          href={copyrightHref}
        />
      )}
      <ImgBlock src={img} />
    </Container>
  );
};

export default ImageBlock;

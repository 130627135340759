import styled from 'styled-components';

export const Center = styled.div`
  display: flex;
  ${({ spacing }) => `
    justify-content: ${spacing ? spacing : 'center'};
    align-items: ${spacing ? spacing : 'center'};
  `}
  flex-flow: ${({ row }) => (row ? 'row' : 'column')};
  height: 100%;
`;
export const CenterVertical = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  height: 100%;
`;
export const CenterHorizontal = styled.div`
  display: flex;
  ${({ row, spacing }) =>
    row
      ? `justify-content: ${spacing ? spacing : 'center'};`
      : `align-items: ${spacing ? spacing : 'center'}`};
  flex-flow: ${({ row }) => (row ? 'row' : 'column')};
  ${({ wrap }) => (wrap ? 'flex-wrap:wrap;' : '')}
`;
export const Margin = styled.div`
  ${({ top, bottom, right, left, fullWidth, width }) => `
    ${top ? `margin-top:${top};` : ''}
    ${bottom ? `margin-bottom:${bottom};` : ''}
    ${right ? `margin-right:${right};` : ''}
    ${left ? `margin-left:${left};` : ''}
    ${fullWidth ? 'width:100%;' : ''}
    ${width ? `width:${width};` : ''}
  `}
`;
export const Padding = styled.div`
  ${({ top, bottom, right, left, fullWidth, width }) => `
    ${top ? `padding-top:${top};` : ''}
    ${bottom ? `padding-bottom:${bottom};` : ''}
    ${right ? `padding-right:${right};` : ''}
    ${left ? `padding-left:${left};` : ''}
    ${fullWidth ? 'width:100%;' : ''}
    ${width ? `width:${width};` : ''}
  `}
`;

export const Logo = styled.img`
  height: 40px;
  margin-top: 10px;
`;

import styled from 'styled-components';
import { CenterHorizontal } from './GeneralHelpers';

export const BodyWrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  padding: 0 250px;
  @media (max-width: 1200px) {
    padding: 0;
  }
`;

export const ContentLayout = (Component) => (props) => {
  return (
    <CenterHorizontal>
      <ContentWrapper>
        <Component {...props} />
      </ContentWrapper>
    </CenterHorizontal>
  );
};
export const HomepageLayout = (Component) => (props) => {
  return (
    <BodyWrapper>
      <CenterHorizontal>
        <Component {...props} />
      </CenterHorizontal>
    </BodyWrapper>
  );
};

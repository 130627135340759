export const headerHeight = 80;

export const contentWidth = 1000;

export const responsiveSizes = {
  mobile: 768,
};

export const isMobile = window.innerWidth < responsiveSizes.mobile;

export const mediaQuerys = {
  mobile: `(max-width: ${responsiveSizes.mobile}px)`,
  desktop: `(min-width: ${responsiveSizes.mobile + 1}px)`,
};
